import { useLazyQuery } from '@apollo/client';
import { ApolloClientService } from '@mono/apollo';
import { logError } from '@mono/data/logging';
import { getCatalogPath } from '@mono/util/url';
import { useEffect, useState } from 'react';

import type { QuestionResponses } from '../types';
import { getCareerInterestRelatedTopicsQuery } from './queries.graphql-gateway';

export type RawRelatedTopic = {
  title: string;
  slug: string;
  type: string;
};

export type RelatedTopic = {
  title: string;
  path: string;
};

// export for test only
export const defaultTopics = [
  { title: 'Python', path: '/catalog/language/python' },
  { title: 'JavaScript', path: '/catalog/language/javascript' },
  { title: 'HTML & CSS', path: '/catalog/language/html-css' },
  { title: 'Web Development', path: '/catalog/subject/web-development' },
  { title: 'Data Science', path: '/catalog/subject/data-science' },
];

export const convertRawRelatedTopic = (
  rawRelatedTopic: RawRelatedTopic
): RelatedTopic => ({
  title: rawRelatedTopic.title,
  path: getCatalogPath({
    categoryType: rawRelatedTopic.type,
    category: rawRelatedTopic.slug,
  }),
});

export const useCareerInterestRelatedTopics = (
  careerInterest: QuestionResponses['careerInterest']
) => {
  const [relatedTopics, setRelatedTopics] = useState<RelatedTopic[]>([]);

  const [fetchRelatedTopics] = useLazyQuery(
    getCareerInterestRelatedTopicsQuery,
    {
      context: { service: ApolloClientService.GraphqlGateway },
    }
  );

  useEffect(() => {
    if (!careerInterest || careerInterest === "I don't know") {
      setRelatedTopics(defaultTopics);
    } else {
      const slug = careerInterest.toLowerCase().replaceAll(' ', '-');

      fetchRelatedTopics({
        variables: { slug },
      })
        .then(({ data }) => {
          const topics = data?.catalogHubPage?.relatedTopics.map(
            (topic: RawRelatedTopic) => convertRawRelatedTopic(topic)
          );
          setRelatedTopics(topics ?? defaultTopics);
        })
        .catch((err) => {
          logError(err, {
            message:
              'AnonOnboardingQuestionnaire: Failed to fetch careerInterest related topics',
          });
          setRelatedTopics(defaultTopics);
        });
    }
  }, [careerInterest, fetchRelatedTopics]);

  return relatedTopics;
};
